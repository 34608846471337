import React from 'react'
import { MDXTag } from '@mdx-js/tag'


import DefaultLayout from "/Users/radu/code/ebe/src/components/BookLayout.js";
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>

      <MDXTag name="p" components={components}>{`Care credincios nu ar dori să asculte predici bune, inspirate și ziditoare, sau… care predicator nu-și dorește să aibă mesaje bine aranjate, fundamentate din punct de vedere biblic și relevante pentru ascultători?!`}</MDXTag>
      <MDXTag name="p" components={components}>{`Acest curs de omiletică, învață arta de a pregăti și a prezenta un mesaj biblic într-o predică expozitivă, și folosește ca material de bază cartea unuia dintre cei mai mari omileticieni contemporani „Arta comunicării adevărului biblic” de J. Hadon Robinson și „Potretul predicatorului” de J. Stood.`}</MDXTag>
      <MDXTag name="p" components={components}>{`De la alegerea pasajului, prin procesul de studiu al textului și actualizare a mesajului biblic, până la alcătuirea celei mai bune schițe de predică și prezentarea, la propriu, a mesajului, cursul este un profesor excelent oferind și metode de autoevaluare.`}</MDXTag>
      <MDXTag name="p" components={components}>{`Niciodată nu vei mai citi un text ca pretext și apoi să predici despre altceva! După acest curs ai toate șansele să predici nu numai frumos ci și cu autoritatea care vine din Cuvântul Lui !`}</MDXTag>
      <MDXTag name="p" components={components}>{`Citat: „ …când un predicator nu reușește să predice Scripturile, își pierde autoritatea. El nu-i mai confruntă pe oameni cu un cuvânt de la Dumnezeu, ci doar cu un alt cuvânt de la oameni. Prin predicarea Scripturii, Dumnezeu merge în întâmpinarea oamenilor… când Dumnezeu se confruntă cu un om și când îi prinde sufletul, se petrece un lucru uimitor.”`}</MDXTag>
           </MDXTag>;
  }

}
export const _frontmatter = {};
    